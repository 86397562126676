import { Route, Routes } from 'react-router-dom';
import Theater from './Theater';
import StopCovid from './StopCovid';
import Florist from './Florist';

const Projects = () => {
  return (
    <div className='projects-container container'>
      <Routes>
        <Route path='/theater' element={ <Theater />} />
        <Route path='/stopcovid' element={ <StopCovid /> } />
        <Route path='/blossom' element={ <Florist /> }/>
      </Routes>
    </div>
  );
}

export default Projects;