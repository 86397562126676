import './Footer.css';
import Mail from '../assets/Mail.svg';
const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer'>
        <div>
          <p>looking for a designer who shows passion in their work?</p>
          <p>You’ve found one!</p>
        </div>
        <div>
          <a href="mailto:elaine.luyanting@gmail.com"><img src={Mail} alt="mail" /></a>
          <a href="mailto:elaine.luyanting@gmail.com">elaine.luyanting@gmail.com</a>
        </div>
      </div>
      <p className='rights'>© 2022. All Rights Reserved to Elaine Lu.</p>
    </div>
  );
}
 
export default Footer;