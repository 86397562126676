import './StopCovid.css';
import Picture1 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-01.png';
import Picture2 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-02.png';
import Picture3 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-03.png';
import Picture4 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-04.png';
import Picture5 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-05.png';
import Picture6 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-06.png';
import Picture7 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-07.png';
import Picture8 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-08.png';
import Picture9 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-09.png';
import Picture10 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-10.png';
import Picture11 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-11.png';
import Picture12 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-12.png';
import Picture13 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-13.png';
import Picture14 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-14.png';
import Picture15 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-15.png';
import Picture16 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-16.png';
import Picture17 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-17.png';
import Picture18 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-18.png';
import Picture19 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-19.png';
import Picture20 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-20.png';
import Picture21 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-21.png';
import Picture22 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-22.png';
import Picture23 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-23.png';
import Picture24 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-24.png';
import Picture25 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-25.png';
import Picture26 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-26.png';
import Picture27 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-27.png';
import Picture28 from '../../assets/stopcovid/Stopcovid- Case study slide deck [Template]-28.png';

const StopCovid = () => {
  const picPaths = [
    Picture1,
    Picture2,
    Picture3,
    Picture4,
    Picture5,
    Picture6,
    Picture7,
    Picture8,
    Picture9,
    Picture10,
    Picture11,
    Picture12,
    Picture13,
    Picture14,
    Picture15,
    Picture16,
    Picture17,
    Picture18,
    Picture19,
    Picture20,
    Picture21,
    Picture22,
    Picture23,
    Picture24,
    Picture25,
    Picture26,
    Picture27,
    Picture28,
  ]

  return (
    <>
      { picPaths && picPaths.map(pic => {
        return (
          <div key={pic} className='stopcovid-pics'>
            <img src={pic} alt="slides" />
          </div>
        )
      }) }
    </>
  )
}

export default StopCovid;