import { Link } from "react-router-dom";
import Close from '../assets/Close.svg';
import Resume from '../assets/Elaine_Lu_UX_Design_Resume.pdf';

import './Sidebar.css';

const Sidebar = ({ sidebar, setSidebar }) => {
  return (
    <div className= {`nav-mobile-sidebar ${sidebar ? '' : 'nav-mobile-disable-sidebar'}`}>
      <div className='nav-mobile-sidebar-links'>
        <ul onClick={() => setSidebar(false)}>
          <li><Link to='/'>PROJECT</Link></li>
          <li><Link to='/about'>ABOUT</Link></li>
          <li><a href={Resume} target='_blank' rel="noreferrer">RESUME</a></li>
        </ul>
        <img src={Close} alt="close" onClick={() => setSidebar(false)} />
      {/* <div className='nav-mobile-sidebar-close' onClick={() => setSidebar(false)}>

      </div> */}
      </div>
    </div>
  );
}
 
export default Sidebar;