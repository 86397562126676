import './StopCovid.css';
import FloristPicture1 from '../../assets/florist/Blossom_case study-01.png';
import FloristPicture2 from '../../assets/florist/Blossom_case study-02.png';
import FloristPicture3 from '../../assets/florist/Blossom_case study-03.png';
import FloristPicture4 from '../../assets/florist/Blossom_case study-04.png';
import FloristPicture5 from '../../assets/florist/Blossom_case study-05.png';
import FloristPicture6 from '../../assets/florist/Blossom_case study-06.png';
import FloristPicture7 from '../../assets/florist/Blossom_case study-07.png';
import FloristPicture8 from '../../assets/florist/Blossom_case study-08.png';
import FloristPicture9 from '../../assets/florist/Blossom_case study-09.png';
import FloristPicture10 from '../../assets/florist/Blossom_case study-10.png';
import FloristPicture11 from '../../assets/florist/Blossom_case study-11.png';
import FloristPicture12 from '../../assets/florist/Blossom_case study-12.png';
import FloristPicture13 from '../../assets/florist/Blossom_case study-13.png';
import FloristPicture14 from '../../assets/florist/Blossom_case study-14.png';
import FloristPicture15 from '../../assets/florist/Blossom_case study-15.png';
import FloristPicture16 from '../../assets/florist/Blossom_case study-16.png';
import FloristPicture17 from '../../assets/florist/Blossom_case study-17.png';
import FloristPicture18 from '../../assets/florist/Blossom_case study-18.png';
import FloristPicture19 from '../../assets/florist/Blossom_case study-19.png';
import FloristPicture20 from '../../assets/florist/Blossom_case study-20.png';
import FloristPicture21 from '../../assets/florist/Blossom_case study-21.png';
import FloristPicture22 from '../../assets/florist/Blossom_case study-22.png';
import FloristPicture23 from '../../assets/florist/Blossom_case study-23.png';
import FloristPicture24 from '../../assets/florist/Blossom_case study-24.png';
import FloristPicture25 from '../../assets/florist/Blossom_case study-25.png';
import FloristPicture26 from '../../assets/florist/Blossom_case study-26.png';
import FloristPicture27 from '../../assets/florist/Blossom_case study-27.png';

const Florist = () => {

  const floristPics = [
    FloristPicture1,
    FloristPicture2,
    FloristPicture3,
    FloristPicture4,
    FloristPicture5,
    FloristPicture6,
    FloristPicture7,
    FloristPicture8,
    FloristPicture9,
    FloristPicture10,
    FloristPicture11,
    FloristPicture12,
    FloristPicture13,
    FloristPicture14,
    FloristPicture15,
    FloristPicture16,
    FloristPicture17,
    FloristPicture18,
    FloristPicture19,
    FloristPicture20,
    FloristPicture21,
    FloristPicture22,
    FloristPicture23,
    FloristPicture24,
    FloristPicture25,
    FloristPicture26,
    FloristPicture27,

  ]

  return (
    <>
      { floristPics && floristPics.map(pic => (
        <div className='stopcovid-pics' key={pic}>
          <img src={pic} alt="slides" />
        </div>
      )) }
    </>
  )
}

export default Florist;