import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home/Home';
import Sidebar from './components/Sidebar';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react'
import Projects from './pages/Projects/Projects';
import ScrollToTop from './components/ScrollToTop';
import About from './pages/About/About';
import Footer from './components/Footer';

function App() {
  const [sidebar, setSidebar] = useState(false);
  const [showScroll, setShowScroll] = useState(false);


  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    })
  },[])
  // console.log(sidebar);

  return (
    <div className={`App ${sidebar ? 'sidebar-disable-scroll' : ''}`}>
      <BrowserRouter>
        <Navbar setSidebar={setSidebar} />
        <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
        { showScroll && <ScrollToTop />}
        <Routes>
          <Route path='/' element={ <Home /> } />
          <Route path='/projects/*' element={ <Projects /> } />
          <Route path='/about' element={ <About /> } />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
