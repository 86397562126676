import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Resume from '../assets/Elaine_Lu_UX_Design_Resume.pdf';
import { useLocation } from 'react-router-dom';

import './Navbar.css';
import Logo from '../assets/logo.png';
import Menu from '../assets/menu.svg';

export default function Navbar({ setSidebar }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
  }, [pathname])

  return (
    <div className='navbar-container'>
      <div className='navbar-mobile'>
        <div className='navbar-mobile-logo'>
          <Link to='/' onClick={() => setSidebar(false)}><img src={Logo} alt="logo" /></Link>
        </div>
        <div className='navbar-mobile-menu' onClick={() => setSidebar(prev => !prev)}>
          <img src={Menu} alt="menu" />
        </div>
      </div>

      <div className='navbar-desktop container'>
        <div className='navbar-desktop-logo'>
          <Link to='/'><img src={Logo} alt="logo" /></Link>
        </div>

        <ul>
          <li><NavLink to='/'>PROJECT</NavLink></li>
          <li><NavLink to='/about'>ABOUT</NavLink></li>
          <li><a href={Resume} target='_blank' rel="noreferrer">RESUME</a></li>
        </ul>
      </div>
      
    </div>
  );
}
