import projectIntro from '../../assets/projectIntro.png';
import EmpathyMap from '../../assets/Empathymap.png';
import Persona from '../../assets/persona.gif';
import CompetetiveAudit from '../../assets/competetiveaudit.png';
import UserJourneyMap from '../../assets/UserJourneyMap.jpg';
import SiteMap from '../../assets/sitemap.png';
import DigitalWireframes from '../../assets/digitalWireframes.png';
import Wireframes from '../../assets/Wireframs.jpg';
import UsabilityStudy from '../../assets/usabilityStudy.png';
import Mockup from '../../assets/mockup.png';
import Fidelity from '../../assets/fidelity.png';
import HomepageCompare from '../../assets/homepage_compare.png';
import SeatCompare from '../../assets/Seat_compare.png';
import SeatCompareTwo from '../../assets/Seat_compare2.png';
import SeatCompareThree from '../../assets/Seat_compare3.png';
import PopupCompare from '../../assets/Pop-upCompare.png';
import ReviewCompare from '../../assets/RevewCompare.png';
import ConfirmCompare from '../../assets/ConfirmCompare.png';
import SearchMovie from '../../assets/SearchMovie.gif';
import SelectTicketType from '../../assets/SelectTicketType.gif';
import SelectSeats from '../../assets/SelectSeats.gif';
import Ass from '../../assets/ASS.gif';
import ReviewPay from '../../assets/reviewPay.gif';
import CheckTickets from '../../assets/CheckTickets.gif';
import StoryBoardOne from '../../assets/storyBoard1.png';
import StoryBoardTwo from '../../assets/storyBoard2.png';

import './Theater.css' ;

const Theater = () => {
  return (
    <section className='project-theater'>
      <div className='project-intro-imgs'>
        <img src={projectIntro} alt="project intro" />
      </div>

      <div className='project-info apply-padding '>
        <div>
          <h3>Project Name:</h3>
          <p>CMOVIE seat reservation app</p>
          <h3>Project Duration:</h3>
          <p>6 weeks</p>
        </div>
        <div>
          <h3>My Role:</h3>
          <p>User research, Visual design, UX design, Interaction design</p>
          
          <h3>Deliverables:</h3>
          <div className='project-info-ul'>
            <ul>
              <li>Personas</li>
              <li>Storyboards</li>
              <li>User Journey Map</li>
              <li>User Flow Diagram</li>
            </ul>
            <ul>
              <li>Competitive Audit</li>
              <li>Empathy Map</li>
              <li>Usability Study</li>
              <li>High-Fidelity Prototype</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='project-vision apply-padding '>
        <div className='project-vision-groups'>
          <h2>Project Vision</h2>
          <p>CMOVIE is a seat reservation app for a movie theater in a rural area so that moviegoers can reserve their seats online and not worry that their favorite seats will be taken if they are running late. For this project, I decide to use a goal-directed design method that revolves around focusing on persona creation, user research, and pain points. This project was developed as a mobile-based application rather than being web-based to showcase my mobile design skillset.</p>
        </div>
        <div className='project-vision-groups'>
          <h2>The Challenges</h2>
          <ol>
            <li>Design an intuitive seat reservation system for the familiar and unfamiliar users</li>
            <li>Provide a seamless & linear reserve seat process</li>
            <li>Create a UI that has a movie atmosphere to provide an immersive purchasing experience</li>
          </ol>
        </div>
      </div>

      <div className='project-research apply-padding'>
        <div className='project-research-empathy'>
          <div>
            <h2>Research Process</h2>
            <p>When preparing for the foundational research, I ask myself questions like:</p>
            <ol type='a'>
              <li>Who What Where When and Why use seat reservation?</li>
              <li>What should I build?</li>
              <li>What are the users’ problems?</li>
              <li>How can I solve these problems?</li>
            </ol>
            <p>I conducted interviews and created empathy maps to better understand the users I’m designing for and their needs. A primary user group identified through the research was college students and busy working adults between the ages of 18 and 45 who go to the cinema at least twice a month.</p>
          </div>
          <div>
            <img src={EmpathyMap} alt="empathy map" />
          </div>
        </div>

        <div className='project-research-persona'>
          <div>
            <img src={Persona} alt="persona" />
          </div>
          <p>Half of them had concerns about having their favorite seat taken when they were running late. Sixty percent (4 female, 2 male) of the researched group (5 female, 5 male) had difficulty choosing the seat they wanted due to limited information or a complicated seat selection process. The study also revealed that 80% had been disturbed during a movie, either because they were too tall and blocked other people’s view, or some were blocked by someone sitting in front of them. </p>
        </div>
      </div>

      <div className='project-research-summary-container apply-padding'>
        <div className='project-research-summary apply-padding'>
          <h3>Based on what I found in the research, I summarized three main pain points:</h3>
          <ol>
            <li><span>Complicated</span> seat reservation process</li>
            <li><span>Hard to choose</span> the seat they want due to a lack of knowledge on screen size and space</li>
            <li>Been blocked or <span>blocked</span> other people during a movie</li>
          </ol>

          <h3>How can I address these pain points in my seat reservation design to provide a better experience for all moviegoers?</h3>
          <ol>
            <li>Design a <span>simplified and intuitive</span> seat reservation system</li>
            <li>Create an <span>auto-select-seat system</span></li>
            <li><span>Mark</span> “Tall people” on the seat map</li>
          </ol>
        </div>
      </div>

      <div className='project-preparation apply-padding'>
        <h2>Preparation Before Design...</h2>
        <p>I also did competetive audit to learn what fuctions to include in the app.  And use the user journey map to create user flow.</p>
        <img src={CompetetiveAudit} alt="competetive audit" />
        <div className='project-preparation-groups'>
          <div>
            <img src={UserJourneyMap} alt="user journey map" />
          </div>
          <div>
            <img src={SiteMap} alt="site map" />
          </div>
        </div>
        <h2>Storyboards</h2>
        <div className='project-preparation-groups'>
          <div>
            <p>Big Picture</p>
            <img src={StoryBoardTwo} alt="story board one" />
          </div>
          <div>
            <p>Close-up</p>
            <img src={StoryBoardOne} alt="story board close-up" />
          </div>
        </div>
      </div>

      <div className='project-wireframes apply-padding'>
        <h2>Wireframes</h2>
        <p>As the first design process progressed, I made certain that screen designs were based on input and insights from user research.Here are some pictures of paper and digital wireframes.</p>
        <div className='project-wireframes-img-container'>
          <div>
            <img src={Wireframes} alt="wireframes" />
          </div>
          <div>
            <img src={DigitalWireframes} alt="digital wireframes" />
          </div>
        </div>
      </div>

      <div className='project-usability apply-padding'>
        <h2>Usability Study</h2>
        <p>I conducted unmoderated usability study with 10 participants. Here are THREE main findings: </p>
        <img src={UsabilityStudy} alt="usability study" />
      </div>

      <div className='project-mockups apply-padding'>
        <h2>Mockups & High-Fidelity prototypes</h2>
        <p>To better address the problems in the usability study, I redesigned the homepage, optimized the seat selection process with real-time seat avaliability, and added the ticket information after successful purchasement. </p>
        <div className='project-mockups-groups'>
          <div>
            <img src={Mockup} alt="mockups" />
          </div>
          <div>
            <img src={Fidelity} alt="fidility prototype" />
          </div>
        </div>
      </div>

      <div className='apply-padding'>
        <h2>Improve the design and repeat the process</h2>
        <p>Based on usability study feedback over four times, here are the main pages comparison between the first version and the lattest version. </p>
        <div className='project-comparison'>
          <div className='project-comparison-groups'>
            <div>
              <h3>Homepage comparison</h3>
            </div>
            <div>
              <img src={HomepageCompare} alt="homepage compare" />
            </div>
          </div>

          <div className='project-comparison-groups'>
            <div>
              <img src={SeatCompare} alt="Seat compare" />
            </div>
            <div>
            </div>
          </div>
        </div>

        

        <div className='project-comparison'>
          <div className='project-comparison-groups'>
            <div>
              <h3>Seat selection process comparison</h3>
            </div>
            <div>
              <img src={SeatCompareTwo} alt="detailed Seat compare" />
            </div>
          </div>

          <div className='project-comparison-groups'>
            <div>
              <img src={SeatCompareThree} alt="seat selecting compare" />
            </div>
            <div>
            </div>
          </div>
        </div>

       

        <div className='project-comparison project-comparison-groups'>
          <div>
            <h3>pop-up message comparison</h3>
          </div>
          <div>
            <img src={PopupCompare} alt="message compare" />
          </div>
        </div>

        <div className='project-comparison project-comparison-groups'>
          <div>
            <img src={ReviewCompare} alt="review compare" />
          </div>
          <div>
            <h3 className='project-comparison-groups-right'>review & pay comparison</h3>
          </div>
        </div>

        <div className='project-comparison project-comparison-groups'>
          <div>
            <h3>confirm ticket comparison</h3>
          </div>
          <div>
            <img src={ConfirmCompare} alt="confirm compare" />
          </div>
        </div>
      </div>

      <div className='project-app-demo apply-padding'>
        <h2>Let’s bring it to life!</h2>
        <div className='project-each-demo project-comparison-groups'>
          <div>
            <p>can scroll to explore, or simply search for it</p>
            <img src={SearchMovie} alt="search movie" />
          </div>
          <div>

          </div>
        </div>

        <div className='project-each-demo project-comparison-groups'>
          <div>

          </div>
          <div>
            <p className='project-comparison-groups-right'>select ticket type and # of tickets at same time</p>
            <img src={SelectTicketType} alt="select ticket type" />
          </div>
        </div>

        <div className='project-each-demo project-comparison-groups'>
          <div>
            <p>See date, time, seat avaliability in one interface</p>
            <img src={SelectSeats} alt="select seats" />
          </div>
          <div>

          </div>
        </div>

        <div className='project-each-demo project-comparison-groups'>
          <div>

          </div>
          <div>
            <p className='project-comparison-groups-right'>Auto-select-seat system, try this bad ass</p>
            <img src={Ass} alt="auto select seats" />
          </div>
        </div>

        <div className='project-each-demo project-comparison-groups'>
          <div>
            <p>review and pay on same page</p>
            <img src={ReviewPay} alt="review and pay" />
          </div>
          <div>

          </div>
        </div>

        <div className='project-each-demo project-comparison-groups'>
          <div>

          </div>
          <div>
            <p className='project-comparison-groups-right'>Check tickets anytime after purchasing</p>
            <img src={CheckTickets} alt="check tickets" />
          </div>
        </div>

      </div>

      <div className='apply-padding project-figma-link'>
        <h2><a href="https://www.figma.com/proto/yLh9Ddi3uxgAYOuE1NVRHF/Movie-Seat-Reservation?node-id=305%3A5640&scaling=scale-down&page-id=1%3A2&starting-point-node-id=305%3A5640" target='_blank' rel="noreferrer">TRY IT YOURSELF!</a></h2>
        <p>scenario: Your friend just watched COCO, and he tells you it’s a great movie! You can’t wait to see it, in fact, you are going today! You plan to go right after dinner around 6:45pm, IMAX will be your first choice. BTW, you are very tall, 190cm+</p>
      </div>

      <div className='apply-padding'>
        <h2>Key Takeaways</h2>
        <p>One of the import thing I learned is that users always want more features, but the more complex the features, the harder it is to keep the design intuitive and simple. It’s really not simple to make a simple design. Therefore as a designer, we need to make trade-offs and not forget the original intention. As you may noticed in the comparison part, I made some major changes, what I did not put here are tons of minor changes, for example fonts, color, capitals, icons etc. I believe there is no best design, only better design, we all need to constanly improve our design and skills sets as we go along the road, and it’s those minor things hidden in the design work that makes a difference. </p>
      </div>
    </section>
  );
}
 
export default Theater;