import AboutProfile from '../../assets/AboutProfile.png';
import AboutCooking from '../../assets/AboutCooking.png';
import AboutDancing from '../../assets/AboutDancing.png';
import AboutNursingHouse from '../../assets/AboutNursingHouse.png';
import AboutSocial from '../../assets/AboutSocial.png';
import AboutTravel from '../../assets/AboutTravel.png';

import './About.css';



const About = () => {
  return (
    <div className='container about'>
      <div className='about-profile'>
        <div>
          <img src={AboutProfile} alt="profile" />
        </div>
        <div>
          <h2>How I Become a UX/UI Designer</h2>
          <p>I have been very fond of design since I was a child. I have designed clothes for my family and friends. Seeing them happily wearing the clothes I designed gave me a great sense of accomplishment.</p>
          <p>I kept this love for design when I grew up. After I graduated, I did education and training, helped many students find jobs they like, and then I realized that my sense of accomplishment came from what I could do for others. So when I was introduced to UX design, I knew immediately that this is what I wanted to do.</p>
          <p>What I like most about UX design is that I get to design things, and help people, and two of my favorite things become one.  I love learning new things from others and constanly improving my designs. I believe there is no best design, only better design.</p>
        </div>
      </div>

      <div className='about-passionate'>
        <h2>Other Things I’m Passionate About</h2>
        <div className='about-interests'>
          <div>
            <img src={AboutTravel} alt="travelling" />
            <p>Roadtrip with friends</p>
          </div>
          <div>
            <img src={AboutCooking} alt="cooking" />
            <p>Cooking food</p>
          </div>
          <div>
            <img src={AboutSocial} alt="social activity" />
            <p>Alzheimer Society Memory Walk</p>
          </div>
          <div>
            <img src={AboutDancing} alt="dacing" />
            <p>Dancing</p>
          </div>
          <div>
            <img src={AboutNursingHouse} alt="caring" />
            <p>Volunteer at Nursing Home</p>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default About;