import './Home.css';
import ProjectTheater from '../../assets/projectTheater.png';
import ProjectFlorist from '../../assets/projectFlorist.png';
import ProjectCovid from '../../assets/projectCovid.png';

import { useNavigate } from 'react-router';

const Home = () => {
  let navigate = useNavigate();
  return (
    <div className='home-container container'>
      <div className='slogan'>
        <h1>Think Hard, Design Better, Stay Curious</h1>
      </div>

      
      <h1 className='welcome'>WELCOME! <span>I'M ELAINE LU</span></h1>
      <p>A UX designer who loves to explore new endeavors and is passionate about improving the lives of others through design.</p>
      
      <div className='projects-covers'>
        <div className='project-main' onClick={() => navigate('/projects/theater')}>
          <img src={ProjectTheater} alt="project theater" />
          <div className='project-hover'>
            <h2>CMOVIE</h2>
            <p>Concept, Branding, mobile UI/UX</p>
          </div>
        </div>
        <div className='project-main' onClick={() => navigate('/projects/blossom')}>
          <img src={ProjectFlorist} alt="project florist" />
          <div className='project-hover'>
            <h2>BLOSSOM</h2>
            <p>Responsive Website</p>
          </div>
        </div>
        <div className='project-main' onClick={() => navigate('/projects/stopcovid')}>
          <img src={ProjectCovid} alt="project covid" />
          <div className='project-hover'>
            <h2>STOPCOVID</h2>
            <p>Responsive Website, mobile App UI/UX</p>
          </div>
        </div>
      </div>

    </div>
  );
}
 
export default Home;