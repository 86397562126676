import ScrollToTopIcon from '../assets/ScrollToTop.svg';

const ScrollToTop = () => {
  return (
    <button className='scroll-to-top' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
      <img src={ScrollToTopIcon} alt="back to top" />
    </button>
  );
}
 
export default ScrollToTop;